import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/jquery.select.js'
$('select.select').select();
$("header .nav a").eq(0).addClass("active")

var swiper = new Swiper(".index-banner .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    loop:true,
    speed:2000,
    autoplay: {
    delay: 3000,
    
    },
    pagination: {
        el: ".index-banner .swiper-pagination",
        clickable :true,
    },
});
var swiper1 = new Swiper(".swiper1 .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    slidesPerView: 4,
    spaceBetween: 60,
    navigation: {
        nextEl: ".swiper1 .swiper-button-next",
        prevEl: ".swiper1 .swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        465: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {  //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 60,
        }
    }
});
var swiper2 = new Swiper(".swiper2 .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    slidesPerView: 4,
    spaceBetween: 60,
    navigation: {
        nextEl: ".swiper2 .swiper-button-next",
        prevEl: ".swiper2 .swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        465: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {  //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 60,
        }
    }
});
var swiper3 = new Swiper(".swiper3 .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    slidesPerView: 4,
    spaceBetween: 60,
    navigation: {
        nextEl: ".swiper3 .swiper-button-next",
        prevEl: ".swiper3 .swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        465: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {  //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 60,
        }
    }
});
var swiper4 = new Swiper(".swiper4 .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    slidesPerView: 4,
    spaceBetween: 60,
    navigation: {
        nextEl: ".swiper4 .swiper-button-next",
        prevEl: ".swiper4 .swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        465: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {  //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 60,
        }
    }
});
var swiper5 = new Swiper(".swiper5 .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    slidesPerView: 4,
    spaceBetween: 60,
    navigation: {
        nextEl: ".swiper5 .swiper-button-next",
        prevEl: ".swiper5 .swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        465: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {  //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 60,
        }
    }
});
var swiper6 = new Swiper(".swiper6 .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    slidesPerView: 4,
    spaceBetween: 60,
    navigation: {
        nextEl: ".swiper6 .swiper-button-next",
        prevEl: ".swiper6 .swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        465: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {  //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 60,
        }
    }
});

$(".tyre-nav li").hover(function() {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".swiper-list .swipers").eq(index).addClass("active").siblings().removeClass("active");
})
$(".tyre-nav li").eq(1).addClass("active");
$(".swiper-list .swipers").eq(1).addClass("active");
var videoswiper = new Swiper(".section2 .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    slidesPerView: 2,
    spaceBetween: 20,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        768: {  //当屏幕宽度大于等于1280
            slidesPerView: 2,
            spaceBetween: 20,
        }
    },
    pagination: {
        el: ".section2 .swiper-pagination",
        clickable :true,
    },
});
$(".play").click(function() {
    $(".video-model").css("display","flex");
    let data = $(this).attr("data");
    let poster = $(this).parent().find(".videopic").attr("src")
    var src = $("<video src=\"" + data + "\" poster=\"" + poster + "\" controls>");
    $(".video-model .video").append(src);
})
$(".out").click(function() {
    $(".video-model").css("display","none");
    $(".video").text("");
})